import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import user from "./reducers/userReducer";
import order from "./reducers/orderReducer";
import items from "./reducers/itemsReducer";

const store = createStore(
  combineReducers({ user, order, items }),
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export default store;
