import React from "react";
import styles from "./fullpageloader.module.css";

const FullPageLoader = () => (
  <div className={`absoluteCentered ${styles.fullPageLoaderIcon}`}>
    Loading...
  </div>
);

export default FullPageLoader;
