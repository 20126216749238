import * as userConstants from "../constants/userConstants";

const initialState = {
  appUserLogingIn: false,
  appUserLoginError: false,
  appUser: JSON.parse(
    sessionStorage.getItem("appUser") ||
      localStorage.getItem("appUser") ||
      "null"
  ),
  stitchUser: null,
  electron: null,
  userRegistering: false,
  userRegisteringError: false,
  registered: false,
  showingBillingInfoModal: false,
  showingAddressInfoModal: false,
  showingInvoicingRequestModal: false,
  showingUserEmailConfigurationModal: false,
  emailConfigurationForce: false,
  emailConfigurationSuccessCallback: Function.prototype,
  emailConfigurationShowIsNeeded: true,
  users: [],
  usersLoading: false,
  usersError: false,
  customers: []
};

// eslint-disable-next-line no-unused-vars
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case userConstants.USER_UPDATE:
      return { ...state, appUser: { ...state.appUser, ...payload } };
    case userConstants.USER_APP_LOGIN_START:
      return { ...state, appUserLogingIn: true, appUserLoginError: null };
    case userConstants.USER_APP_LOGIN_RECEIVE:
      return {
        ...state,
        appUser: payload,
        appUserLogingIn: false,
        appUserLoginError: null
      };
    case userConstants.USER_APP_LOGIN_ERROR:
      return { ...state, appUserLogingIn: false, appUserLoginError: payload };
    case userConstants.USER_STITCH_RECEIVE:
      return { ...state, stitchUser: payload };
    case userConstants.USER_REGISTER_RESET:
      return { ...state, registered: false };
    case userConstants.USER_REGISTER:
      return { ...state, userRegistering: true, appUserLoginError: null };
    case userConstants.USER_REGISTER_ERROR:
      return {
        ...state,
        userRegistering: false,
        userRegisteringError: payload
      };
    case userConstants.USER_REGISTER_RECEIVE:
      return {
        ...state,
        registered: true,
        userRegistering: false
      };
    case userConstants.USER_SET_VERIFIED_EMAILS:
      return {
        ...state,
        appUser: { ...state.appUser, verified_emails: payload }
      };
    case userConstants.USERS_GET:
      return {
        ...state,
        usersLoading: true
      };
    case userConstants.USERS_GET_SET:
      return {
        ...state,
        users: payload,
        usersLoading: false,
        usersError: false
      };
    case userConstants.USERS_GET_ERROR:
      return {
        ...state,
        users: [],
        usersLoading: false,
        usersError: true
      };
    case userConstants.USER_BILLING_INFO_SHOW:
      return {
        ...state,
        showingBillingInfoModal: true
      };
    case userConstants.USER_BILLING_INFO_CLOSE:
      return {
        ...state,
        showingBillingInfoModal: false
      };
    case userConstants.USER_ADDRESS_INFO_SHOW:
      return {
        ...state,
        showingAddressInfoModal: true
      };
    case userConstants.USER_ADDRESS_INFO_CLOSE:
      return {
        ...state,
        showingAddressInfoModal: false
      };
    case userConstants.USER_ADDRESS_INFO_SHOW:
      return {
        ...state,
        showingAddressInfoModal: true
      };
    case userConstants.USER_SET_EMAIL:
      return { ...state, appUser: { ...state.appUser, email: payload } };
    case userConstants.USER_EMAIL_CONFIGURATION_CLOSE:
      return {
        ...state,
        showingUserEmailConfigurationModal: false
      };
    case userConstants.USER_EMAIL_CONFIGURATION_SHOW:
      return {
        ...state,
        showingUserEmailConfigurationModal: true
      };
    case userConstants.USER_INVOICING_REQUEST_CLOSE:
      return {
        ...state,
        showingInvoicingRequestModal: false
      };
    case userConstants.USER_LOGISTICS_LOAD_CUSTOMERS:
      return {
        ...state,
        customers: payload
      };
    default:
      return state;
  }
};

export default userReducer;
