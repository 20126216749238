import * as itemsConstants from "../constants/itemsConstants";

const initialState = {
  itemsLoading: false,
  itemsError: false,
  items: []
};

// eslint-disable-next-line no-unused-vars
const itemsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case itemsConstants.ITEMS_GET:
      return { ...state, itemsLoading: true, itemsError: false };
    case itemsConstants.ITEMS_RECEIVE:
      return {
        ...state,
        items: payload.filter(({ hidden = false }) => !hidden),
        itemsLoading: false,
        itemsError: false
      };
    case itemsConstants.ITEMS_ERROR:
      return { ...state, itemsLoading: false, itemsError: payload };
    default:
      return state;
  }
};

export default itemsReducer;
