/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  message,
  Result,
  Row,
  Spin,
  Typography
} from "antd";
import React, { useCallback, useReducer, useEffect } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import userService from "../../../services/user.service";
import AttachVaccinationPart from "../components/AttachVaccinationPart";

const { Title, Paragraph } = Typography;

const initialState = {
  status: "idle", // "idle" | "loading-form" | "loading-form-error" | "sending-form"
  error: "",
  front: null,
  back: null
};

const AttachVaccinationCard = ({ realm }) => {
  const { token } = useParams();
  const [form] = Form.useForm();
  const [state, dispatch] = useReducer((state, { action, payload }) => {
    switch (action) {
      case "start-loading-form":
        return { ...state, status: "loading-form" };
      case "fetching-form-error":
        return { ...state, status: "loading-form-error", error: payload };
      case "loaded-form":
        return { ...state, status: "idle" };
      case "start-sending-form":
        return { ...state, status: "sending-form" };
      case "end-sending-form":
        return { ...state, status: "idle" };
      case "form-sent":
        return { ...state, status: "form-sent" };
      case "receive-front-attachment":
        return { ...state, front: payload };
      case "receive-back-attachment":
        return { ...state, back: payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    dispatch({ action: "start-loading-form" });
    realm
      .callFunction("isValidToken", [
        { token, type: "vaccinationcard-request" }
      ])
      .then(response => {
        if (!response.valid) {
          dispatch({
            action: "fetching-form-error",
            payload: "The provided token is not valid."
          });
        } else {
          dispatch({ action: "loaded-form" });
        }
      })
      .catch(() => {
        dispatch({
          action: "fetching-form-error",
          payload:
            "There was an error while verifying the token. Please try again in a few moments"
        });
      });
  }, [form, realm, token]);

  const onFrontChanged = useCallback(
    payload => dispatch({ action: "receive-front-attachment", payload }),
    []
  );

  const onBackChanged = useCallback(
    payload => dispatch({ action: "receive-back-attachment", payload }),
    []
  );

  const submitAttachments = useCallback(() => {
    const key = "submitting";

    message.loading({
      content: "Sending...",
      key,
      duration: 9999
    });

    const { front, back } = state;

    userService
      .uploadPatientVaccinationCardPictures({ front, back, token })
      .then(({ error, success }) => {
        if (success) {
          dispatch({ action: "form-sent" });
          return;
        }
        if (error) {
          message.error({ content: error, key });
          return;
        }
        message.error({
          content:
            "There was an error while sending the pictures. Please try again in a few moments",
          key
        });
      })
      .catch(() => {
        message.error({
          content:
            "There was an error while sending the pictures. Please try again in a few moments",
          key
        });
      });
  }, [state, token]);

  if (state.status === "loading-form") {
    return <Spin />;
  }

  return (
    <div
      style={{
        width: "90%",
        maxWidth: "1000px",
        margin: "34px auto"
      }}
    >
      <style>{`
      body {
        overflow: auto !important;
      }
      `}</style>
      {state.status === "form-sent" ? (
        <Result
          status="success"
          title="Vaccination Card Sent"
          subTitle="Thank you for submitting the information."
        />
      ) : (
        <>
          {state.status === "loading-form-error" ? (
            <Alert type="error" message={state.error} />
          ) : (
            <>
              <Typography.Title>
                Attach your COVID Vaccination Card
              </Typography.Title>
              <Row>
                <Col span={12}>
                  <AttachVaccinationPart
                    title="Front"
                    onChange={onFrontChanged}
                  />
                </Col>
                <Col span={12}>
                  <AttachVaccinationPart
                    title="Back"
                    onChange={onBackChanged}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Button
                    onClick={submitAttachments}
                    type="primary"
                    disabled={!state.front || !state.back}
                  >
                    Submit Vaccination Card
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  realm: state.user.stitchUser
});

export default connect(mapStateToProps)(AttachVaccinationCard);
