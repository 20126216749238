export const SUBMITTED_GET = "ORDER::SUBMITTED_GET";
export const SUBMITTED_GET_RECEIVE = "ORDER::SUBMITTED_GET_RECEIVE";
export const SUBMITTED_GET_RECEIVE_PAGINATED =
  "ORDER::SUBMITTED_GET_RECEIVE_PAGINATED";
export const SUBMITTED_GET_ERROR = "ORDER::SUBMITTED_GET_ERROR";
export const GEXID_CHECK = "ORDER::GEXID_CHECK";
export const GEXID_CHECK_RECEIVE = "ORDER::GEXID_CHECK_RECEIVE";
export const GEXID_CHECK_ERROR = "ORDER::GEXID_CHECK_RECEIVE";
export const GEXID_CHECK_INPUT = "ORDER::GEX_CHECK_INPUT";
export const CREDIT_CHECK = "ORDER::CREDIT_CHECK";
export const CREDIT_CHECK_RECEIVE = "ORDER::CREDIT_CHECK_RECEIVE";
export const CREDIT_CHECK_ERROR = "ORDER::CREDIT_CHECK_ERROR";
