/* eslint-disable no-nested-ternary */
import React, { Suspense, lazy, useMemo } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FullPageLoader from "./components/fullpageloader/FullPageLoader";
import SessionKeeper from "./components/sessionKeeper";
import AttachVaccinationCard from "./pages/order/views/AttachVaccinationCard";

const EmailVerification = lazy(() =>
  import("./pages/auth/views/EmailVerification")
);
const ReportReviewerRoutes = lazy(() =>
  import("./pages/report-reviewer/views/routes")
);
const LabWorkerRoutes = lazy(() => import("./pages/lab-worker/views/routes"));
const OrderingPhysicianRoutes = lazy(() =>
  import("./pages/ordering-physician/views/routes")
);
const PhysicianRoutes = lazy(() => import("./pages/physician/routes"));
const LogisticRoutes = lazy(() => import("./pages/logistic/views/routes"));
const SubmitInformation = lazy(() =>
  import("./pages/order/views/SubmitInformation")
);
const OrderByToken2FA = lazy(() =>
  import("./pages/order/views/OrderByToken2FA")
);
//const NurseRoutes = lazy(() => import("./pages/nurse/views/routes"));
const AuthRoutes = lazy(() => import("./pages/auth/views/routes"));

const RoleRoutingComponents = {
  op: OrderingPhysicianRoutes,
  guest: OrderingPhysicianRoutes,
  lw: LabWorkerRoutes,
  rr: ReportReviewerRoutes,
  logistics: LogisticRoutes,
  physician: PhysicianRoutes,
  // nurse: NurseRoutes,
  needs_authentication: AuthRoutes
};

const App = ({ user }) => {
  const { role = "needs_authentication" } = user;
  const Routes = useMemo(() => RoleRoutingComponents[role], [role]);
  return (
    <>
      <Router>
        <Switch>
          <Suspense fallback={<FullPageLoader />}>
            <Route
              exact
              path="/order-token/:token"
              component={OrderByToken2FA}
            />
            <Route
              exact
              path="/submit-information/:token"
              component={SubmitInformation}
            />
            <Route
              exact
              path="/email_confirmation/:token"
              component={EmailVerification}
            />
            <Route
              exact
              path="/submit-covidcard/:token"
              component={AttachVaccinationCard}
            />
            <Routes />
            <SessionKeeper />
          </Suspense>
        </Switch>
      </Router>
    </>
  );
};
const mapStateToProps = state => ({
  user: state.user.appUser || {}
});

export default connect(mapStateToProps)(App);
