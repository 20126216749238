import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import "./index.css";
import "./helpers.css";
import "antd/dist/antd.css";
import * as userActions from "./store/actions/userActions";
import { connect } from "react-redux";
import App from "./App";
import FullPageLoader from "./components/fullpageloader/FullPageLoader";

const Main = props => {
  const { stitchUser, connectStitch } = props;
  useEffect(() => {
    connectStitch();
  }, [connectStitch]);

  if (!stitchUser) {
    return <FullPageLoader />;
  }

  return <App />;
};

const mapStateToProps = state => ({
  stitchUser: state.user.stitchUser,
  appUser: state.user.appUser
});

const mapDispatchToProps = dispatch => ({
  connectStitch: () => dispatch(userActions.connectStitch())
});

const MainComponent = connect(mapStateToProps, mapDispatchToProps)(Main);

ReactDOM.render(
  <Provider store={store}>
    <MainComponent />
  </Provider>,
  document.getElementById("root")
);
