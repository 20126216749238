import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import * as userActions from "../../store/actions/userActions";

const SessionKeeper = ({ refreshUser }) => {
  const location = useLocation();

  useEffect(() => {
    refreshUser();
  }, [location, refreshUser]);
  useEffect(() => {
    window.addEventListener("beforeunload", refreshUser);
    return () => {
      window.removeEventListener("beforeunload", refreshUser);
    };
  }, [refreshUser]);
  return <></>;
};

const mapStateToProps = () => {};

const mapDispatchToProps = dispatch => ({
  refreshUser: user => dispatch(userActions.refreshSessionData(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionKeeper);
