export const USER_UPDATE = "USER::UPDATE";

export const USER_APP_AUTHENTICATE = "USER::APP_AUTHENTICATE";
export const USER_APP_LOGIN_START = "USER::APP_LOGIN_START";
export const USER_APP_LOGIN_ERROR = "USER::APP_LOGIN_ERROR";
export const USER_APP_LOGIN_GUEST = "USER:APP_LOGIN_GUEST";
export const USER_APP_LOGIN_RECEIVE = "USER::APP_LOGIN_RECEIVE";

export const USER_STITCH_LOGIN = "USER::STITCH_LOGIN";
export const USER_STITCH_RECEIVE = "USER::STITCH_RECEIVE";
export const USER_STITCH_ERROR = "USER::STITCH_ERROR";

export const USER_REGISTER = "USER::REGISTER";
export const USER_REGISTER_RESET = "USER::REGISTER_RESET";
export const USER_REGISTER_RECEIVE = "USER::REGISTER_RECEIVE";
export const USER_REGISTER_ERROR = "USER::REGISTER_ERROR";

export const USERS_GET = "USERS::GET";
export const USERS_GET_SET = "USERS::GET_SET";
export const USERS_GET_ERROR = "USERS::GET_ERROR";

export const ELECTRON_CONNECT = "ELECTRON::CONNECT";

export const USER_BILLING_INFO_SHOW = "USER::BILLING_INFO_SHOW";
export const USER_BILLING_INFO_CLOSE = "USER::BILLING_INFO_CLOSE";

export const USER_ADDRESS_INFO_SHOW = "USER::ADDRESS_INFO_SHOW";
export const USER_ADDRESS_INFO_CLOSE = "USER::ADDRESS_INFO_CLOSE";

export const USER_EMAIL_CONFIGURATION_SHOW = "USER::EMAIL_CONFIGURATION_SHOW";
export const USER_EMAIL_CONFIGURATION_CLOSE = "USER::EMAIL_CONFIGURATION_CLOSE";

export const USER_INVOICING_REQUEST_SHOW = "USER::INVOICING_REQUEST_SHOW";
export const USER_INVOICING_REQUEST_CLOSE = "USER::INVOICING_REQUEST_CLOSE";

export const USER_LOGISTICS_LOAD_CUSTOMERS = "USER-LOGISTICS::LOAD_CUSTOMERS";

export const USER_SET_EMAIL = "USER::SET_EMAIL";

export const USER_SET_VERIFIED_EMAILS = "USER::SET_VERIFIED_EMAILS";
