import * as orderConstants from "../constants/ordersConstants";

const initialState = {
  ordersLoading: false,
  ordersError: false,
  orders: [],
  summary: {},
  checkingId: false,
  checked: false,
  checkIdError: false,
  checkIdInput: "",
  ordersTotal: 0
};

// eslint-disable-next-line no-unused-vars
const ordersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case orderConstants.SUBMITTED_GET:
      return {
        ...state,
        ordersLoading: true,
        orders: [],
        appUserLoginError: null
      };
    case orderConstants.SUBMITTED_GET_RECEIVE:
      return {
        ...state,
        orders: payload,
        ordersLoading: false,
        ordersError: null
      };
    case orderConstants.SUBMITTED_GET_RECEIVE_PAGINATED:
      return {
        ...state,
        orders: payload.orders,
        summary: { ...state.summary, ...payload.summary },
        ordersTotal: payload.summary.total,
        ordersLoading: false,
        ordersError: null
      };
    case orderConstants.SUBMITTED_GET_ERROR:
      return { ...state, ordersLoading: false, ordersError: payload };
    case orderConstants.GEXID_CHECK:
      return { ...state, checkingId: true, checkIdError: null };
    case orderConstants.GEXID_CHECK_RECEIVE:
      return {
        ...state,
        checkingId: false,
        checked: payload,
        checkIdError: null
      };
    case orderConstants.GEXID_CHECK_ERROR:
      return { ...state, checkingId: false, checkIdError: payload };
    case orderConstants.GEXID_CHECK_INPUT:
      return { ...state, checkIdInput: payload };
    case orderConstants.CREDIT_CHECK:
      return { ...state, checkingCredit: true, creditCheckError: null };
    case orderConstants.CREDIT_CHECK_RECEIVE:
      return {
        ...state,
        checkingCredit: false,
        creditCheckError: null
      };
    case orderConstants.CREDIT_CHECK_ERROR:
      return { ...state, checkingCredit: false, creditCheckError: payload };
    default:
      return state;
  }
};

export default ordersReducer;
