import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Form, Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";

const AttachVaccinationPart = ({ title, onChange }) => {
  const fileUpload = useRef();
  const [stage, setStage] = useState("idle"); // idle | loading | errored
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");

  const onFileChanged = useCallback(e => {
    const [selectedFile] = e.target.files;

    if (selectedFile) {
      setFile(selectedFile);
      setStage("idle");
    }
  }, []);

  useEffect(() => {
    if (!file) return;
    const reader = new FileReader();

    reader.onload = () => {
      setPreview(reader.result);
      onChange(reader.result);
    };

    reader.readAsDataURL(file);
  }, [file, onChange]);

  return (
    <>
      <Typography.Title level={3}>{title}</Typography.Title>
      <Form.Item>
        <div style={{ padding: "8px" }}>
          {preview && (
            <img
              style={{ maxWidth: "100%" }}
              src={preview}
              alt={`Vaccination Card ${title}`}
            />
          )}
        </div>
        <Button
          onClick={() => fileUpload.current && fileUpload.current.click()}
        >
          <CloudUploadOutlined /> Upload
        </Button>
        <input
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          ref={fileUpload}
          onChange={onFileChanged}
        />
      </Form.Item>
    </>
  );
};

export default AttachVaccinationPart;
