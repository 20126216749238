/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import { AnonymousCredential, Stitch } from "mongodb-stitch-browser-sdk";
import { message } from "antd";
import * as userConstants from "../constants/userConstants";
import stitchId from "../constants/envConstants";
import userService from "../../services/user.service";

const guestUser = {
  _id: "guestuser",
  name: "Guest",
  role: "guest"
};

export const authenticate = () => dispatch =>
  dispatch(userConstants.USER_APP_AUTHENTICATE, {});

export const logout = () => dispatch => {
  if (sessionStorage.getItem("loggedAs")) {
    sessionStorage.setItem("appUser", sessionStorage.getItem("loggedAs"));
    sessionStorage.removeItem("loggedAs");
    location.reload();
    return;
  }
  sessionStorage.removeItem("appUser");
  localStorage.removeItem("appUser");
  dispatch({
    type: userConstants.USER_APP_LOGIN_RECEIVE,
    payload: null
  });
  window.location.href = "/";
};

export const loadCustomers = ({ env = "prod" } = {}) => (
  dispatch,
  getState
) => {
  const { stitchUser: client } = getState().user;

  client.callFunction("getXeroCustomers", [{ env }]).then(response => {
    dispatch({
      type: userConstants.USER_LOGISTICS_LOAD_CUSTOMERS,
      payload: response.contacts
    });
  });
};

export const guestLogin = () => dispatch => {
  sessionStorage.setItem("appUser", JSON.stringify(guestUser));
  localStorage.setItem("appUser", JSON.stringify(guestUser));
  dispatch({
    type: userConstants.USER_APP_LOGIN_RECEIVE,
    payload: guestUser
  });
};

export const getAllUsers = () => (dispatch, getState) => {
  dispatch({
    type: userConstants.USERS_GET
  });
  const { stitchUser: client } = getState().user;
  client.callFunction("getAllUsers", []).then(response => {
    if (response.error) {
      dispatch({
        type: userConstants.USERS_GET_ERROR,
        payload: response.error
      });
    } else {
      dispatch({
        type: userConstants.USERS_GET_SET,
        payload: response
      });
    }
  });
};

export const setUserVerifiedEmails = emails => dispatch => {
  dispatch({ type: userConstants.USER_SET_VERIFIED_EMAILS, payload: emails });
};

export const login = ({ remember = false, ...credentials }) => (
  dispatch,
  getState
) => {
  dispatch({ type: userConstants.USER_APP_LOGIN_START });

  const { stitchUser: client } = getState().user;

  client.callFunction("login", [credentials]).then(response => {
    if (response.error) {
      dispatch({
        type: userConstants.USER_APP_LOGIN_ERROR,
        payload: response.error
      });
    } else {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem("appUser", JSON.stringify(response));
      message.success(`Welcome back ${response.email}`);
      dispatch({
        type: userConstants.USER_APP_LOGIN_RECEIVE,
        payload: response
      });
    }
  });
};

export const refreshSessionData = () => (dispatch, getState) => {
  const { stitchUser: client, appUser } = getState().user;
  if (appUser && appUser._id) {
    client
      .callFunction("refreshSessionData", [{ userId: appUser._id }])
      .then(response => {
        if (response.error) {
          message.error(response.error);
        } else {
          localStorage.setItem("appUser", JSON.stringify(response));
          dispatch({
            type: userConstants.USER_APP_LOGIN_RECEIVE,
            payload: response
          });
        }
      });
  }
};

export const logAsUser = user => (dispatch, getState) => {
  sessionStorage.setItem("loggedAs", JSON.stringify(getState().user.appUser));
  sessionStorage.setItem("appUser", JSON.stringify(user));
  location.reload();
};

export const updateUser = user => (dispatch, getState) => {
  const newUser = { ...getState().user.appUser, ...user };

  if (sessionStorage.getItem("appUser")) {
    sessionStorage.setItem("appUser", JSON.stringify(newUser));
  }
  if (localStorage.getItem("appUser")) {
    localStorage.setItem("appUser", JSON.stringify(newUser));
  }

  dispatch({ type: userConstants.USER_UPDATE, payload: user });
};

export const updateUserInfo = user => (dispatch, getState) => {
  const { stitchUser: client } = getState().user;
  client
    .callFunction("updateCustomerInfo", [
      { userId: user._id.toString(), customer_info: user.customer_info }
    ])
    .then(response => {
      if (response.error) {
        message.error(response.error);
      } else {
        if (sessionStorage.getItem("appUser")) {
          sessionStorage.setItem("appUser", JSON.stringify(user));
        }
        if (localStorage.getItem("appUser")) {
          localStorage.setItem("appUser", JSON.stringify(user));
        }
        dispatch({ type: userConstants.USER_UPDATE, payload: user });
      }
    });
};

export const init = () => dispatch => {
  dispatch({ type: userConstants.USER_REGISTER_RESET });
};

export const register = data => (dispatch, getState) => {
  dispatch({ type: userConstants.USER_REGISTER });
  const { stitchUser: client } = getState().user;
  client.callFunction("register", [data]).then(response => {
    if (response.error) {
      dispatch({
        type: userConstants.USER_REGISTER_ERROR,
        payload: response.error
      });
    } else {
      message.success(`User registered successfully!`);
      dispatch({
        type: userConstants.USER_REGISTER_RECEIVE,
        payload: response
      });
    }
  });
};
//USER_EMAIL_CONFIGURATION_SHOW
export const showUserEmailConfigurationModal = () => dispatch =>
  dispatch({
    type: userConstants.USER_EMAIL_CONFIGURATION_SHOW
  });

export const setUserEmail = email => (dispatch, getState) => {
  const { appUser } = getState().user;

  appUser.email = email;

  sessionStorage.setItem("appUser", JSON.stringify(appUser));

  dispatch({
    type: userConstants.USER_SET_EMAIL,
    payload: email
  });
};

export const closeUserEmailConfigurationModal = () => ({
  type: userConstants.USER_EMAIL_CONFIGURATION_CLOSE
});

export const showInvoicingRequestModal = () => ({
  type: userConstants.USER_INVOICING_REQUEST_SHOW
});

export const closeInvoicingRequestModal = () => ({
  type: userConstants.USER_INVOICING_REQUEST_CLOSE
});

export const showBillingInfoModal = () => ({
  type: userConstants.USER_BILLING_INFO_SHOW
});

export const closeBillingInfoModal = () => ({
  type: userConstants.USER_BILLING_INFO_CLOSE
});

export const showAddressInfoModal = () => ({
  type: userConstants.USER_ADDRESS_INFO_SHOW
});

export const closeAddressInfoModal = () => ({
  type: userConstants.USER_ADDRESS_INFO_CLOSE
});

export const stitchReceive = user => ({
  type: userConstants.USER_STITCH_RECEIVE,
  payload: user
});

export const stitchError = () => ({
  type: userConstants.USER_STITCH_ERROR
});

export const connectStitch = () => dispatch => {
  const client = Stitch.initializeDefaultAppClient(stitchId);
  client.auth
    .loginWithCredential(new AnonymousCredential())
    .then(() => dispatch(stitchReceive(client)))
    .catch(() => dispatch(stitchError()));
};
