import BaseService from "./base.service";
import env from "../env";

class UserService extends BaseService {
  getUserVerifiedEmails(userId, email) {
    return this.realm().callFunction("getUserVerifiedEmails", [
      { userId, email, env }
    ]);
  }

  saveNotificationConfiguration(params) {
    return this.realm().callFunction("saveNotificationConfiguration", [params]);
  }

  getUserNotificationConfiguration(params) {
    return this.realm().callFunction("getUserNotificationConfiguration", [
      params
    ]);
  }

  getUserNotifications(params) {
    return this.realm().callFunction("getUserNotifications", [params]);
  }

  getVerifiedMethods({ userId }) {
    return this.realm().callFunction("verifyContactMethod", [
      { userId, pin: null }
    ]);
  }

  markNotificationRead({ notificationId }) {
    return this.realm().callFunction("markNotificationRead", [
      { notificationId }
    ]);
  }

  verifyContactMethod({ userId, email, phone, contact_method, pin }) {
    return this.realm().callFunction("verifyContactMethod", [
      { userId, email, phone, contact_method, pin }
    ]);
  }

  sendVerificationCode({ userId, email, phone, contact_method }) {
    return this.realm().callFunction("sendVerificationCode", [
      { userId, email, phone, contact_method }
    ]);
  }

  sendEmailVerification(userId, email) {
    return this.realm().callFunction("sendEmailVerification", [
      { userId, email, env }
    ]);
  }

  confirmEmailWithToken(token) {
    return this.realm().callFunction("confirmEmailWithToken", [{ token, env }]);
  }

  verifyAndSaveEmail(userId, email) {
    return this.realm().callFunction("verifyAndSaveEmail", [
      { userId, email, env }
    ]);
  }

  requestPatientsInfoCompletion(userId, patientsIds) {
    return this.realm().callFunction("requestPatientInfoCompletion", [
      { userId, patientsIds, env }
    ]);
  }

  requestPatientVaccinationCard(userId, patientsIds) {
    return this.realm().callFunction("requestPatientVaccinationCard", [
      { userId, patientsIds, env }
    ]);
  }

  uploadPatientVaccinationCardPictures({ front, back, token }) {
    return this.realm().callFunction("uploadPatientVaccinationCardPictures", [
      { front, back, token, env }
    ]);
  }

  getAttachments(attachmentIds) {
    return this.realm().callFunction("getAttachments", [{ attachmentIds }]);
  }

  getUserItemPricing(userId, itemDbName) {
    return this.realm().callFunction("getItemPricing", [
      { userId, itemDbName }
    ]);
  }
}

const userService = new UserService();

export default userService;
